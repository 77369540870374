$(document).ready(() => {
	$('#hero-slider').sliderPro({
		slideAnimationDuration: 2000,
		autoplayDelay: 7000,
		waitForLayers: true,
		touchSwipe: false,
		fadeOutPreviousSlide: true,
		autoplay: true,
		loop: false,
		slideDistance: 0,
		shuffle: true,
		autoSlideSize: true,
		autoHeight: true,
		arrows: true,
		fadeArrows: false,
		responsive: true,
		width: "100%",
		buttons: false,
	});
});

